import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import {Link as RouterLink} from "react-router-dom";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Hidden from '@material-ui/core/Hidden';
import MenuRoundIcon from '@material-ui/icons/MenuRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';



const useStyles = makeStyles(theme => ({
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
      flexWrap: 'wrap',
    },
    toolbarTitle: {
      flexGrow: 1,
    },
    logo: {
        maxWidth: 160,
    },
    link: {
        fontSize: 18,
        color: '#616161',
        textTransform: 'none',
        fontWeight: '400'
    },
    menu: {
        minWidth: '150px',
        color: 'inherit'
    }
  }));


export default function Main() {

    const classes = useStyles();

    const [anchorElCollapsed, setAnchorElCollapsed] = React.useState(null);

    const handleClickCollapsed = event => {
        setAnchorElCollapsed(event.currentTarget);
    };

    const handleCloseCollapsed = () => {
        setAnchorElCollapsed(null);
    };


    const [anchorElSolutions, setAnchorElSolutions] = React.useState(null);

    const handleClickSolutions = event => {
        setAnchorElSolutions(event.currentTarget);
    };

    const handleCloseSolutions = () => {
        setAnchorElSolutions(null);
    };

    const [anchorElResources, setAnchorElResources] = React.useState(null);

    const handleClickResources = event => {
        setAnchorElResources(event.currentTarget);
    };

    const handleCloseResources = () => {
        setAnchorElResources(null);
    };


    return (
        <Container maxWidth="lg" style={{background: '#fafafa'}} component="main">
        <AppBar position="static" style={{background: '#fafafa'}} elevation={0} className={classes.appBar}>
            <Toolbar className={classes.toolbar} >
            <Grid
                container 
                alignContent='space-between'
                alignItems='center'
            >
                <Grid item md={2} xs={11}>
                    <Link component={RouterLink} to="/">
                        <img src="./img/keytrack_logo.png" component={RouterLink} to="/" alt="logo" className={classes.logo} />
                    </Link>
                </Grid>
                <Hidden mdUp>
                <Grid item xs={1}>
                   
                    
                </Grid>
                </Hidden>
                <Hidden smDown>
                    <Grid item xs={6}>
                    
                    <Box display="flex" flexDirection="row" p={1} m={2}>

                    

                    <Menu
                        id="simple-menu"
                        anchorEl={anchorElSolutions}
                        keepMounted
                        open={Boolean(anchorElSolutions)}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                        onClose={handleCloseSolutions}
                        >
                        
                        <MenuItem onClick={handleCloseResources} component={RouterLink} className={classes.menu} to="/investors">For Investors</MenuItem>

                        <MenuItem onClick={handleCloseResources} component={RouterLink}  className={classes.menu} to="/companies">For Companies</MenuItem>
                    </Menu>

                    
                    
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorElResources}
                        keepMounted
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                        open={Boolean(anchorElResources)}
                        onClose={handleCloseResources}
                        >
                        
                        <MenuItem component={Link} className={classes.menu}  href="https://medium.com/keytrack">Blog</MenuItem>
                    </Menu>

                    {/* <Button component={RouterLink} to="/pricing" className={classes.link} color="inherit" >
                            About Us
                    </Button> */}

                    </Box>
                    </Grid>
                    <Grid item xs={4}>
                    
                    </Grid>
                </Hidden>
            </Grid>
            </Toolbar>
        </AppBar>
        </Container>
    )
}