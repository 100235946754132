import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';

import Navbar from './Navbar';
import Home from './Home';
import Imprint from './Legal1';
import Terms from './Terms';
import Footer from './Footer';
import { createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import 'typeface-roboto';
import ReactGA from 'react-ga';
import CookieConsent from "react-cookie-consent";
ReactGA.initialize('UA-158336478-1');
ReactGA.pageview(window.location.pathname + window.location.search);


const theme = createMuiTheme({
  palette: {
    primary: {main: '#1976d2',
    },
    background: {
        default: '#fafafa',
    },
  },
});

const styles = {
  button: {
    color: '#fff',
    background: '#1976d2',
    borderRadius: '4px'
  },
  declineButton: {
    background: 'none',
    color: '#1976d2',
    borderRadius: '4px',
    border: '1px solid rgba(25, 118, 210, 0.5)'
  },
  bar: {
    background: 'rgb(224, 221, 221)',
    color: 'rgba(0, 0, 0, 0.87)',
    
  }
};

export default function Main() {

  return (
    <React.Fragment>
        <ThemeProvider theme={theme}>
        <Router>
      <CssBaseline />
      <CookieConsent 
      enableDeclineButton
      style={styles.bar}
      buttonStyle	= {styles.button}
      onDecline={() => {
        ReactGA.set({ anonymizeIp: true })
      }}
      declineButtonStyle = {styles.declineButton}
      >This website uses cookies to enhance the user experience.</CookieConsent>
      <Navbar />
      <Switch>
          <Route path="/imprint">
            <Imprint />
          </Route>
          <Route path="/terms">
            <Terms />
          </Route>
          <Route path="/">
            <Home />
          </Route>
      </Switch>
      <Footer />
      </Router>
      </ThemeProvider>
    </React.Fragment>
  );
}
