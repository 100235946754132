import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '120px',
        marginBottom: '150px',
      },
  }));


export default function Main() {
    const classes = useStyles();

    return(
    <React.Fragment>
    <Container maxWidth="lg" className={classes.root} component="main">
    <Grid
        container 
        alignContent='space-between'
        alignItems='center'
    >

        <Grid item xs={12}>
            <Typography component="h4" variant="h4"  color="textPrimary" gutterBottom>
                Imprint
            </Typography>
            <Typography color="textSecondary" component="p">
            Keytrack Service GmbH, Malmöer Str. 14, 10439 Berlin<br/>
Managing Director: René Kampschulte<br/>
Trade Register: Amtsgericht Charlottenburg, HRB 210769 B
            </Typography>
        </Grid>

      </Grid>
    </Container>
    </React.Fragment>
    )
}