import React , {useState }from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles';
import Lottie from 'react-lottie';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import {Link as RouterLink} from "react-router-dom";
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '50px',
      },
    image: {
        maxWidth: '100%',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    paragraph: {
        padding: '0px 200px'
    },
    text: {
        fontSize: 16,
        display: 'block'
    }
  }));

const defaultOptions = {
    loop: true,
    autoplay: true, 
  };



export default function Main() {
    const classes = useStyles();


    const [inputs, setInputs] = useState({
        email: "",
      });
      
    
      const handleOnChange = event => {
        event.persist();
        setInputs(prev => ({
          ...prev,
          [event.target.id]: event.target.value
        }));
      };


    return(
    <React.Fragment>
    <Container maxWidth="lg" className={classes.root} component="main">
    <Grid
        container 
        alignContent='space-between'
        alignItems='center'
        spacing={2}
    >

        <Grid item md={7} xs={12}>
            <Typography variant="h6" color="textSecondary" component="p">

            Keytrack tried to help investors use portfolio company data better.<br/><br/>

            Investors want to analyze KPIs and financial metrics but managing data is tricky. We built a software and services to handle data in various formats, sources and frequencies.<br/><br/>

            It didn't catch on. Read more about our journey  <Link href="https://www.linkedin.com/posts/rene-k_vc-data-portfoliomanagement-activity-6900027465368961024-qFno"  >
            here
                    </Link>.<br/><br/>

            All former customers have been offboarded.<br/><br/><br/><br/><br/><br/>

            </Typography>
        </Grid>


    </Grid>
 
    </Container>
    
    <Box style={{background: "#ffffff"}}>
    
    </Box>

    <Container maxWidth="lg" className={classes.root} component="main">
    <Grid
        container 
        alignContent='space-between'
        alignItems='center'
        spacing={7}
    >
        

       
      </Grid>
    </Container>
    
    </React.Fragment>
    )
}