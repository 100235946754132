import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import {Link as RouterLink} from "react-router-dom";


function Copyright() {
    return (
      <Typography variant="body2" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://keytrack.io/">
          Keytrack
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

const useStyles = makeStyles(theme => ({
    '@global': {
      ul: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
      },
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
      flexWrap: 'wrap',
    },
    toolbarTitle: {
      flexGrow: 1,
    },
    link: {
      margin: theme.spacing(1, 1.5),
    },
    heroContent: {
      padding: theme.spacing(8, 0, 6),
    },
    cardHeader: {
      backgroundColor:
        theme.palette.type === 'dark' ? theme.palette.grey[700] : theme.palette.grey[200],
    },
    cardPricing: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(2),
    },
    footer: {
      borderTop: `1px solid ${theme.palette.divider}`,
      marginTop: theme.spacing(8),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
      },
    },
  }));



export default function Main() {
    const classes = useStyles();

    return (
      <Box style={{background: "rgb(60, 72, 78)", color: 'white'}}>
      <Container maxWidth="md" component="footer" className={classes.footer}>
        <Grid container spacing={4} justify="space-between">
            <Grid item xs={6} sm={3}>
              <Typography variant="h6" gutterBottom>
                Contact
              </Typography>
              <ul>
                 
              </ul>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="h6" gutterBottom>
                Resources
              </Typography>
              <ul>
                  <li key='Blog'>
                    <Link href="https://medium.com/keytrack" variant="subtitle1" style={{color: 'white'}}>
                      Blog
                    </Link>
                  </li>
                  <li key='Terms'>
                    <Link component={RouterLink} to="/terms" variant="subtitle1" style={{color: 'white'}}>
                        Terms & Conditions
                    </Link>
                  </li>
              </ul>
            </Grid>

            <Grid item xs={6} sm={3}>
              <Typography variant="h6" gutterBottom>
                Legal
              </Typography>
              <ul>
                  <li key='Imprint'>
                    <Link component={RouterLink} to="/imprint" variant="subtitle1" style={{color: 'white'}}>
                        Imprint
                    </Link>
                  </li>
              </ul>
            </Grid>
        </Grid>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
      </Box>
    )
}