import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '120px',
        marginBottom: '150px',
      },
    contentBlock: {
        marginBottom: '50px'
    }
  }));


export default function Main() {
    const classes = useStyles();

    return(
    <React.Fragment>^
    <Container maxWidth="lg" className={classes.root} component="main">
    <Grid
        container 
        alignContent='space-between'
        alignItems='center'
    >

        <Grid item  xs={12}>
            <Typography component="h4" variant="h4"  color="textPrimary" gutterBottom>
                Terms & Conditions
            </Typography>
        </Grid>
        <Grid item className={classes.contentBlock} xs={12}>
            <Typography variant="body2" color="textSecondary" component="p">
            These Terms and Conditions (“Terms and Conditions”) are published by Keytrack Service GmbH (“Keytrack”) whose registered office is at Malmöer Str. 14, 10439 Berlin, Germany (“Company”) and are effective from September 7th 2020 (the “Effective Date”) for all Customers who signed up on or after that day.<br/><br/>
By logging into your Keytrack account you agree to be bound by these Terms & Conditions unless your organization has a separate Agreement in effect with us, in which event the terms of that Agreement will govern your use of the Service and these Terms and Conditions do not apply.<br/><br/>
You are deemed to have agreed on behalf of any entity for whom you use the Service. You warrant that you have the authority to act on behalf of any person or entity for whom You are using the Service.<br/>
            </Typography>
        </Grid>

        ​ <Typography component="h5" variant="h5"  color="textPrimary" gutterBottom>DEFINITIONS</Typography>
        <Typography variant="body2" color="textSecondary" component="p">
                The following words and expressions shall have the following meanings: <br/> <br/>

                "Authorised User" - any of Your employees who access the Service;
                <br/><br/>"Business Day" - each day which is not a Saturday or Sunday or a bank or public holiday in Germany;
                <br/><br/>“Confidential Information” - all information and materials provided by one party to another under or in relation to these Terms and Conditions which are marked confidential or is manifestly by its nature confidential;
                <br/><br/>"Company Content" - all data, information and material owned by or licensed to Company and comprised within the Service, but excluding Customer Data;
                <br/><br/>“Customer” - “You”
                <br/><br/>"Customer Data" - all data, information and material input or uploaded to the Service or transmitted through the Service by the Customer and/or any Authorised User, including Personal Data;
                <br/><br/>“Data Protection Legislation" - the General Data Protection Regulations 2016 (“GDPR”), the Data Protection Act 2018, the California Consumer Privacy Act (“CCPA”) and any other legislation relating to the processing of personal data;
                <br/><br/>"Intellectual Property Rights" - patents, patentable rights, copyright, design rights, utility models, trade marks (whether or not any of the above are registered), trade names, rights in domain names, rights in inventions, rights in data, database rights, rights in know-how and confidential information, and all other intellectual and industrial property and similar or analogous rights existing under the laws of any country and all pending applications for and right to apply for or register the same (present, future and contingent, and including all renewals, extensions, revivals and all accrued rights of action);
                <br/><br/>“Service" - The provision of the Keytrack Portfolio Reporting software platform (the “Service(s)”)
                <br/><br/>“Subscription" - the Subscription to which You sign up either on the Keytrack platform or in a separate document;
                <br/><br/>“Service Capacity” - the number of Users, Companies and Portfolios set out in the Subscriptions description
                <br/><br/>"Service Fees" - the fees set out in the Subscriptions description
                <br/><br/>"Software" - any software owned by or licensed to the Company and which forms part of, or is used in the provision the Service.
                <br/><br/>"You" - If You are entering into these Terms and Conditions on behalf of a company, organization or another legal entity You refers to that entity and where the context permits You refers to an Authorised User of the Service.

                <br/><br/>In these Terms and Conditions:

                <br/><br/>    a. words in the singular include the plural;

                <br/><br/>    b. reference to a person includes a legal person (such as a limited company) as well as a natural person;

                <br/><br/>    c. clause headings are for convenience only and shall not affect the construction of these Terms and Conditions;

                <br/><br/>    d. reference to "including" or any similar terms in these Terms and Conditions shall be treated as being by way of example and shall not limit the general applicability of any preceding words; and

                <br/><br/>    e. reference to any legislation shall be to that legislation as amended, extended or re-enacted from time to time and to any subordinate provision made under that legislation.
                <br/><br/> 
        </Typography>
 <br/><br/>            <Typography component="h5" variant="h5"  color="textPrimary" gutterBottom>1. SAAS SERVICES</Typography>
 <Typography variant="body2" color="textSecondary" style={{width: '100%'}} component="p">

1.1 Subject to these Terms and Conditions, Company will use commercially reasonable efforts to provide Customer the Services.                                    
 </Typography>

 <br/><br/>            <Typography component="h5" variant="h5"  color="textPrimary" gutterBottom>2. GRANT OF LICENSE AND SCOPE OF AUTHORISED USE</Typography>
 <Typography variant="body2" color="textSecondary" component="p">

           2.1 Subject to full payment of the applicable Services Fees and the other provisions of these Terms and Conditions, You are granted a non-transferable, non-exclusive licence, for the term as specified in the Subscription description and including any automatic renewal, to access and use the Service within the Service Capacity. Without prejudice to clause 2.2, You may not sub-license the right to access and/or use the Service to any third party. All rights in and to the Service, (including the Company Content but excluding Customer Data) and Software are reserved to the Company.
 <br/><br/>                2.2 Only You and the Authorised Users are licensed to access and use the Service and solely for Your internal business purposes.
 <br/><br/>                2.3 You will not, directly or indirectly:
 <br/><br/>    f. except to the extent permitted by law, reverse engineer, decompile, disassemble or otherwise attempt to discover the source code, object code or underlying structure, ideas, know-how or algorithms relevant to the Service or the Software;

 <br/><br/>    g. modify, translate, or create derivative works based on the Service or any Software (except to the extent expressly permitted by the Company);

 <br/><br/>    h. use the Service or any Software for timesharing or service bureau purposes or otherwise for the benefit of a third; or

 <br/><br/>    i. remove any proprietary notices or labels from the Service.

 <br/><br/>    j. permit any third party to access or use the Service or use the same on behalf of any third party (which includes operating any form of facility on behalf of any third party or operating a software bureau or similar service);

 <br/><br/>    k. create Internet "links" to the Service or "frame" or "mirror" any Company Content on any other server or wireless or Internet-based device;

 <br/><br/>    l. not attempt to circumvent security, licence control or other protection mechanisms, or tamper with, hack into or otherwise disrupt the Service or Software or any associated website, computer system, server, router or any other internet-connected device;

 <br/><br/>    m. employ any hardware, software, device or technique to pool connections or reduce the number of devices or users that directly access or use the Service (sometimes referred to as 'virtualisation', 'multiplexing' or 'pooling') in order to circumvent any restrictions on scope of authorised use contained in these Terms and Conditions.

 <br/><br/>                2.4 You shall not, and shall ensure that Authorised Users shall not use the Service to:

 <br/><br/>    a. upload, store, post, email, transmit or otherwise make available any content that infringes any Intellectual Property Rights or data protection, privacy or other rights of any other person, is defamatory or in breach of any contractual duty or any obligation of confidence, is obscene, threatening, inciteful of violence or hatred, blasphemous, discriminatory (on any ground), knowingly false or misleading, or that does not comply with all applicable laws and regulations or is otherwise objectionable or prohibited;

 <br/><br/>    b. upload any Customer Data in that infringes the Intellectual Property Rights of any third party;

 <br/><br/>    c. impersonate any person or entity or otherwise misrepresent the Your relationship with any person or entity;

 <br/><br/>    d. engage in any fraudulent activity or further any fraudulent purpose;

 <br/><br/>    e. provide false identity information;

 <br/><br/>    f. attempt to gain unauthorised access to the Service or its related systems or networks,

 <br/><br/>	and shall not permit any third party to do any of the foregoing.

 <br/><br/>2.5 	You shall permit the Company or its authorised agents at all reasonable times during the term as set out in the Subscription description and for a period of 6 months after the termination of these Terms and Conditions to audit the use of the Service in order to establish whether You are complying with the terms of these Terms and Conditions. If any audit reveals that You have under paid any Fees, including by exceeding the Service Capacity, or breaching the Terms and Conditions then without prejudice to the Company's other rights:

 <br/><br/>    g. You shall pay to the Company an amount equal to the underpayment within 10 Business Days of the date of the relevant audit;

 <br/><br/>    h. the Company shall be entitled to suspend access to Service while the parties discuss what action should be taken.
 <br/><br/>
 </Typography>

 <br/><br/>            <Typography component="h5" variant="h5"  color="textPrimary" gutterBottom>3. CONFIDENTIALITY; PROPRIETARY RIGHTS</Typography>
 <Typography variant="body2" color="textSecondary" component="p">
                3.1 Each party (the “Receiving Party”) understands that the other party (the “Disclosing Party”) has disclosed or may disclose business, technical or financial information relating to the Disclosing Party’s business (hereinafter referred to as “Proprietary Information” of the Disclosing Party).  Proprietary Information of Company includes non-public information regarding features, functionality and performance of the Service.  Proprietary Information of Customer includes non-public data provided by Customer to Company to enable the provision of the Services (“Customer Data”). The Receiving Party agrees: (i) to take reasonable precautions to protect such Proprietary Information, and (ii) not to use (except in performance of the Services or as otherwise permitted herein) or divulge to any third person any such Proprietary Information.  The Disclosing Party agrees that the foregoing shall not apply with respect to any information that the Receiving Party can document (a) is or becomes generally available to the public, or (b) was in its possession or known by it prior to receipt from the Disclosing Party, or (c) was rightfully disclosed to it without restriction by a third party, or (d) was independently developed without use of any Proprietary Information of the Disclosing Party or (e) is required to be disclosed by law.  
 <br/><br/>                3.2 Customer shall own all right, title and interest in and to the Customer Data. Company shall own and retain all right, title and interest in and to (a) the Services and Software, all improvements, enhancements or modifications thereto, (b) any software, applications, inventions or other technology developed in connection with the Services, and (c) all intellectual property rights related to any of the foregoing.     
 <br/><br/>                3.3 Notwithstanding anything to the contrary, Company shall have the right collect and analyze data and other information relating to the provision, use and performance of various aspects of the Services and related systems and technologies (including, without limitation, information concerning Customer Data and data derived therefrom), and Company will be free (during and after the term hereof) to (i) use such information and data to improve and enhance the Services and for other development, diagnostic and corrective purposes in connection with the Services and other Company offerings, and (ii) disclose such data solely in aggregate or other de-identified form in connection with its business.
 <br/><br/>
 </Typography>
 <br/><br/>            <Typography component="h5" variant="h5"  color="textPrimary" gutterBottom>4. PAYMENT OF FEES</Typography>
 <Typography variant="body2" color="textSecondary" component="p">
               4.1 Customer will pay Company the then applicable fees described in the Subscription description for the Services (the “Fees”).  If Customer’s use of the Services exceeds the Service Capacity set forth on the Subscription description or otherwise requires the payment of additional fees (per the terms of these Terms and Conditions), Customer shall be billed for such usage and Customer agrees to pay the additional fees in the manner provided herein.  Company reserves the right to change the Fees or applicable charges and to institute new charges and Fees at the end of the Initial Service Term or then‑current renewal term, upon thirty (30) days prior notice to Customer (which may be sent by email). If Customer believes that Company has billed Customer incorrectly, Customer must contact Company no later than 60 days after the closing date on the first billing statement in which the error or problem appeared, in order to receive an adjustment or credit.
 <br/><br/>                4.2 All amounts described in the Subscription description shall be deemed to be exclusive of any VAT which shall be charged additionally as applicable.
 <br/><br/>                4.3 Company will bill through an invoice and full payment must be received by Company thirty (30) days after the mailing date of the invoice.  Unpaid amounts are subject to a finance charge of 1.5% per month on any outstanding balance plus all expenses of collection and may result in immediate termination of Service.
 <br/><br/>
 </Typography>
 <br/><br/>            <Typography component="h5" variant="h5"  color="textPrimary" gutterBottom>5. TERM AND TERMINATION</Typography>
 <br/><br/><Typography variant="body2" color="textSecondary" component="p">
             5.1 Subject to earlier termination as provided below, these Terms and Conditions are for the term as specified in the Subscription description, and shall be automatically renewed for additional periods of the same time as the original order, unless either party requests termination at least thirty (30) days prior to the end of the then-current term. In the event that You renew beyond the Initial Service Term, the Company shall have the right to amend the terms of these Terms and Conditions subject to approval by You.
 
 <br/><br/>                5.2 In addition to any other remedies it may have, either party may no longer be bound by these Terms and Conditions, including the Subscription description, upon thirty (30) days’ notice (or without notice in the case of non-payment), if:

 <br/><br/>                a. the other party materially breaches any of the terms or conditions of these Terms and Conditions.

 <br/><br/>                b. becomes insolvent, makes composition with its creditors, has a receiver or administrator of its undertaking or the whole or a substantial part of its assets appointed, or an order is made, or an effective resolution is passed, for its administration, receivership, liquidation, winding-up or other similar process, or has any distress, execution or other process levied or enforced against the whole or a substantial part of its assets (which is not discharged, paid out, withdrawn or removed within 28 days), or is subject to any proceedings which are equivalent or substantially similar to any of the foregoing under any applicable jurisdiction, or ceases to trade or threatens to do so.

 <br/><br/>                5.3 On termination, You will pay in full for the Services up to and including the last day on which the Services are provided. Upon any termination, the Company will make all Customer Data available to You for electronic retrieval for a period of thirty (30) days, but thereafter the Company may, but is not obligated to, delete stored Customer Data.

 <br/><br/>                5.4 All sections of these Terms and Conditions which by their nature should survive termination will survive termination, including, without limitation, accrued rights to payment, confidentiality obligations, warranty disclaimers, and limitations of liability.

 <br/><br/>                5.5 Without prejudice to clause 5.1, the Company may, in addition, and without liability, terminate these Terms and Conditions, or alternatively, may suspend access to and use of any Services and/or the Service, by giving You written notice if:

 <br/><br/>                c. any provision of clause 2.3 is breached; and/or

 <br/><br/>                d. You are in persistent or repeated breach of any of Your obligations under these Terms and Conditions

 <br/><br/>
 </Typography>
<Typography component="h5" variant="h5"  color="textPrimary" gutterBottom>6. WARRANTY AND DISCLAIMER</Typography>
 <Typography variant="body2" color="textSecondary" component="p">
       6.1 Company shall use reasonable efforts to maintain the Services in a manner which minimizes errors and interruptions in the Services. Services may be temporarily unavailable for scheduled maintenance or for unscheduled emergency maintenance, either by Company or by third-party providers, or because of other causes beyond Company’s reasonable control, but Company shall use reasonable efforts to provide advance notice in writing or by e-mail of any scheduled service disruption.
 <br/><br/>                6.2 The Company does not warrant that the Services will be uninterrupted or error free; nor does it make any warranty as to the results that may be obtained from use of the Services.
  
 </Typography>
 <br/><br/>
 <br/><br/>            <Typography component="h5" variant="h5"  color="textPrimary" gutterBottom>7. LIMITATION OF LIABILITY</Typography>
 <Typography variant="body2" color="textSecondary" component="p">
             7.1 Save in respect of gross negligence, deliberate default or fraud the Company shall not be liable for any loss (including but not limited to any direct, indirect, consequential or special damages) suffered by the Customer as a result of the provision of the Services.
             <br/><br/>
 </Typography>
 <br/><br/>            <Typography component="h5" variant="h5"  color="textPrimary" gutterBottom>8. FORCE MAJEURE</Typography>
 <Typography variant="body2" color="textSecondary" component="p">
               8.1 Neither party will be liable to the other for any failure or delay in performing its obligations under these Terms and Conditions which arises because of any circumstances which it cannot reasonably be expected to control (which shall include Act of God, explosion, flood, tempest, fire or accident, war or threat of war, sabotage, insurrection, civil disturbance or requisition, acts, restrictions, regulations, bye-laws, prohibitions or measures of any kind on the part of any governmental, parliamentary or local authority, import or export regulations or embargoes, strikes, lock-outs or other industrial actions or trade disputes (whether involving personnel of the Company or a third party), difficulties in obtaining raw materials, labour, fuel, parts or machinery or breakdown in machinery, or interruption or failure of the Internet or of any network, telecommunications, power supply or infrastructure, or any provider of any of the foregoing, but shall not include shortage or lack of available funds on Your part), provided that it:
 <br/><br/>    i. notifies the other in writing as soon as reasonably practicable about the nature and extent of the circumstances and likely effects;
 <br/><br/>    j. uses reasonable efforts to mitigate the effects of the circumstances so as to minimise or avoid any adverse impact on the other; and
 <br/><br/>    k. uses reasonable efforts to resume performance as soon as reasonably practicable.
 <br/><br/>
 </Typography>
 <br/><br/>            <Typography component="h5" variant="h5"  color="textPrimary" gutterBottom>9. GENERAL</Typography>
 <Typography variant="body2" color="textSecondary" component="p">

 9.1 All notices under these Terms and Conditions will be in writing and will be deemed to have been duly given when received, if personally delivered; when receipt is electronically confirmed, if transmitted by facsimile or e-mail; the day after it is sent, if sent for next day delivery by recognized overnight delivery service; and upon receipt, if sent by certified or registered mail, return receipt requested.
 <br/><br/>    9.2 Unless the parties expressly agree otherwise in writing, if a party:
 <br/><br/>    a. fails to exercise or delays exercising or only exercises partially any right or remedy provided under these Terms and Conditions or by law; or

 <br/><br/>    b. agrees not to exercise or to delay exercising any right or remedy provided under these Terms and Conditions or by law;

 <br/><br/>then that party shall not be deemed to have waived and shall not be precluded or restricted from further exercising that or any other right or remedy.

 <br/><br/>9.3 	No agency, partnership, joint venture, or employment is created as a result of these Terms and Conditions and neither party has any authority of any kind to bind the other party in any respect whatsoever.

 <br/><br/>9.4	 If any provision of these Terms and Conditions is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that these Terms and Conditions will otherwise remain in full force and effect and enforceable.

 <br/><br/>9.5 	These Terms and Conditions are not assignable, transferable or sub-licensable by You except with the Company’s prior written consent. The Company may transfer and assign any of its rights and obligations under these Terms and Conditions without consent.

 <br/><br/>9.6 	These Terms and Conditions are the complete and exclusive statement of the mutual understanding of the parties and supersedes and cancels all previous written and oral agreements, communications and other understandings relating to the subject matter of these Terms and Conditions. All waivers and modifications must be in a writing signed by both parties, except as otherwise provided herein. Provided always that nothing in this clause 9.7 will operate to limit or exclude any liability for fraud or fraudulent misrepresentation, no other representations or terms shall apply or form part of these Terms and Conditions and each party acknowledges that it has not been influenced to agree to these Terms and Conditions by, and shall have no rights or remedies (other than for breach of contract) in respect of, anything the other party has said or done or committed to do, except as expressly recorded in these Terms and Conditions.

 <br/><br/>9.7 	These Terms and Conditions are governed by the laws of Germany. Both parties submit to the exclusive jurisdiction of the German courts in relation to any dispute arising out of or in connection with these Terms and Conditions or its subject matter, but the Company is also entitled to apply to any court worldwide for injunctive or other remedies in order to protect or enforce its Intellectual Property Rights.
 </Typography>
            

    </Grid>
    </Container>
    </React.Fragment>
    )
}